import { DateTime } from "luxon";
import MarkdownViewer from "../MarkdownViewer";

export default function Notification({ notification, onDismissClick }) {
  const date = () => {
    const notificationDate = DateTime.fromMillis(notification.createdAt);
    const now = DateTime.now();

    if (!now.hasSame(notificationDate, "year")) {
      return notificationDate.toLocaleString(DateTime.DATE_MED);
    } else if (now.hasSame(notificationDate, "day")) {
      return "Today";
    } else if (now.hasSame(notificationDate, "month") && now.day === notificationDate.day + 1) {
      return "Yesterday";
    } else {
      return notificationDate.toLocaleString({ month: "short", day: "numeric" });
    }
  };

  return (
    <div className={`${notification?.seen ? "text-muted" : ""}`}>
      <div className="d-flex justify-content-between align-items-center">
        {date()}
        {notification.seen ? (
          <i className="fa-solid fa-check" />
        ) : (
          <button
            type="button"
            className="btn btn-link"
            style={{ fontSize: "0.85rem" }}
            onClick={() => {
              onDismissClick(notification.id);
            }}
          >
            Dismiss
          </button>
        )}
      </div>
      <div onClick={()=>{onDismissClick(notification.id);}}>
        <MarkdownViewer markdown={notification.text} className={notification.seen ? 'seen-notification' : 'new-notification'} />
      </div>
      <hr className="my-1" />
    </div>
  );
}
