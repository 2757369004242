import "../Recipe.scss";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Helpers from "../../../services/Helpers";
import AccordionRecipe from "./AccordionRecipe";
import ReviewStars from "../../components/ReviewStars";
import Constants from "../../../services/Constants";
import FullScreenImage from "./FullScreenImage";
import Notes from "../../components/Notes";
import ChildlessRecipe from "./ChildlessRecipe";
import FeaturedIn from "./FeaturedIn";
import RecipeTiming from "./RecipeTiming";
import PortionSelector from "../../components/PortionSelector";
import usePDF from "../../../services/PDFHook";
import { Modal } from "bootstrap";
import MemberOnlyFeatureModal from "../../components/MemberOnlyFeatureModal";
import StashButton from "../../Search/components/StashButton";
import Allergens from "./Allergens";

export default function Recipe(props) {
  let history = useHistory();
  const { contexts, documentTypes, recipeComponentCategories } = Constants();
  const { assetUrl, isNotEmpty, makeUrlLookGood } = Helpers();
  const [recipe, setRecipe] = useState(null);
  const [childRecipes, setChildRecipes] = useState([]);
  const { pdfStore } = usePDF(props.member.preferences, documentTypes.RECIPE, { recipe: recipe, childRecipes: childRecipes });
  const [showNotes, setShowNotes] = useState(false);
  const [lightBoxOpened, setLightBoxOpened] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [collapseAll, setCollapseAll] = useState(false);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, []);

  useEffect(() => {
    if (expandAll) {
      setCollapseAll(false);
    }
  }, [expandAll]);

  useEffect(() => {
    if (collapseAll) {
      setExpandAll(false);
    }
  }, [collapseAll]);

  useEffect(() => {
    if (props.recipes.length > 0) {
      const parentRecipe = props.recipes.find((r) => r.id === props.recipeId);
      const componentRecipeIds = parentRecipe.components.filter((c) => c.category === recipeComponentCategories.RECIPE).map((c) => c.recipeId);
      const childRecipes = props.recipes.filter((r) => r.id !== props.recipeId && componentRecipeIds.indexOf(r.id) === -1);
      const orderedChildRecipes = [];
      for (let childRecipeId of props.childRecipeIds) {
        const childRecipe = childRecipes.find((r) => r.id === childRecipeId);
        if (isNotEmpty(childRecipe)) {
          orderedChildRecipes.push(childRecipe);
        }
      }
      for (let recipeId of componentRecipeIds) {
        const childRecipe = props.recipes.find((r) => r.id === recipeId);
        if (isNotEmpty(childRecipe)) {
          orderedChildRecipes.push(childRecipe);
        }
      }
      setRecipe(parentRecipe);
      setChildRecipes(orderedChildRecipes);
    }
  }, [props.recipes]);

  function download() {
    if (props.member.hasTrial) {
      const element = document.getElementById("trial-feature-modal");
      const trialFeatureModal = Modal.getOrCreateInstance(element);
      trialFeatureModal.show();
    } else {
      pdfStore.reset();
      setDownloading(true);
      props.recordDownload({ recipeId: props.recipeId });
      pdfStore.download();
    }
  }

  useEffect(() => {
    if (pdfStore.fileDownloaded) {
      setDownloading(false);
    }
  }, [pdfStore.fileDownloaded]);

  // TODO: enable expand notes when there are notes
  /*  useEffect(() => {
      if (!showNotes) {
        const memberNote = props.noteStore.memberNotes.find(n => n.documentId === props.recipeId);
        const recipe = props.recipes.find(r => r.id === props.recipeId);
        if (isNotEmpty(memberNote) || (isNotEmpty(recipe) && recipe.notes.length > 0)) {
          setShowNotes(true);
        }
      }
    }, [props.noteStore.memberNotes, props.noteStore.documentPublicNotes, props.recipes]);*/

  function confirmDeleteCustomizations() {
    if (props.deleteCustomizations !== undefined) {
      props.deleteCustomizations();
    }
    const element = document.getElementById("reset-modal");
    const resetModal = Modal.getOrCreateInstance(element);
    resetModal.hide();
  }

  const fromMealPlannerSearch = isNotEmpty(props.mmpStore) && isNotEmpty(props.mmpStore.state.plan.id) && isNotEmpty(props.mmpStore.state.plan.mealSlot);

  function recipes() {
    const recipes = childRecipes.map((r, index) => (
      <AccordionRecipe
        key={r.id}
        index={index}
        member={props.member}
        stashStore={props.stashStore}
        noteStore={props.noteStore}
        context={props.context}
        parentRecipeId={recipe.id}
        recipe={r}
        recipes={props.rawRecipes}
        modals={props.modals}
        canShowNutritionFacts={props.canShowNutritionFacts}
        canShowActionButtons={!fromMealPlannerSearch}
        batchingContext={false}
        expandAll={expandAll}
        collapseAll={collapseAll}
        specialScalingIds={props.specialScalingIds}
        updatingCheckboxes={props.updatingCheckboxes}
        changeCookingMethod={props.changeCookingMethod}
        updateCheckboxes={props.updateCheckboxes}
        deleteChildRecipe={props.deleteChildRecipe}
        updateAdditionalPortions={props.updateChildRecipeAdditionalPortions}
        prepareDataForNutritionFacts={props.prepareDataForNutritionFacts}
        setExpandAll={setExpandAll}
        setCollapseAll={setCollapseAll}
      />
    ));
    return childRecipes.length > 0 ? (
      <div className="accordion mb-3">
        {recipes}
        <AccordionRecipe
          member={props.member}
          stashStore={props.stashStore}
          noteStore={props.noteStore}
          context={props.context}
          recipe={recipe}
          recipes={props.rawRecipes}
          modals={props.modals}
          canShowNutritionFacts={props.canShowNutritionFacts}
          lastRecipe={true}
          canShowActionButtons={!fromMealPlannerSearch}
          batchingContext={false}
          expandAll={expandAll}
          collapseAll={collapseAll}
          specialScalingIds={props.specialScalingIds}
          updatingCheckboxes={props.updatingCheckboxes}
          changeCookingMethod={props.changeCookingMethod}
          updateCheckboxes={props.updateCheckboxes}
          prepareDataForNutritionFacts={props.prepareDataForNutritionFacts}
          setExpandAll={setExpandAll}
          setCollapseAll={setCollapseAll}
        />
      </div>
    ) : (
      <ChildlessRecipe
        noteStore={props.noteStore}
        recipe={recipe}
        recipes={props.rawRecipes}
        updatingCheckboxes={props.updatingCheckboxes}
        modals={props.modals}
        changeCookingMethod={props.changeCookingMethod}
        updateCheckboxes={props.updateCheckboxes}
      />
    );
  }

  function restoreDefaultRecipeButton() {
    let result = null;
    if (isNotEmpty(recipe) && isNotEmpty(props.restoreChildRecipes)) {
      const deletedChildRecipes = props.deletedChildRecipes.filter((dcr) => dcr.parentRecipeId === recipe.id);
      if (deletedChildRecipes.length > 0) {
        result = (
          <div className="d-flex w-md-100 p-3 pb-0 p-md-2 mb-md-2 align-items-center mx-auto flex-fill">
            <div className="border bg-light flex-fill mb-0">
              <p className="text-muted p-2 pt-3">
                You've removed recipe components.
                <br />
                <button className="btn btn-link p-0" onClick={() => props.restoreChildRecipes(recipe.id)}>
                  <i className="fas fa-undo-alt" /> Click to restore
                </button>
              </p>
            </div>
          </div>
        );
      }
    }
    return result;
  }

  function showResetRecipeModal() {
    const element = document.getElementById("reset-modal");
    const resetModal = Modal.getOrCreateInstance(element);
    resetModal.show();
  }

  function noteBadge() {
    let result = null;
    let notesCount = recipe.notes.length;
    const memberNote = props.noteStore.memberNotes.find((n) => n.documentId === recipe.id);
    // if (isNotEmpty(memberNote)) {
    //   notesCount = notesCount + 1;
    // }
    if (notesCount > 0) {
      result = <span className="notes-badge badge bg-white text-primary">{notesCount}</span>;
    }
    return result;
  }

  function changePortions(portions) {
    if (props.context === contexts.STANDALONE_RECIPE) {
      props.changePortions(portions);
    } else if (props.context === contexts.WIZARD_PLAN) {
      props.changePortions(props.recipeId, portions);
    } else {
      // No other valid contexts for now
    }
  }

  function portions() {
    // TODO: every store should set something meaningful in customPortions
    let portions = recipe.portions;
    if (props.context === contexts.WIZARD_PLAN) {
      portions = recipe.customPortions;
    }
    return portions;
  }

  const stashItem = props.stashStore.items.filter((i) => i.documentType === documentTypes.RECIPE).find((i) => i.documentId === props.recipeId);

  return isNotEmpty(recipe) ? (
    <div className="container-fluid p-0 p-sm-3 p-md-4 strip-padding">
      <FullScreenImage opened={lightBoxOpened} image={recipe.photo} caption={recipe.name} onCloseRequest={() => setLightBoxOpened(false)} />
      <div className="rounded row bg-white gx-0 justify-content-md-center recipe-wrap">
        {/* Print only header*/}
        <div className="d-none d-print-block mb-0 pb-0 d-flex aligns-items-center">
          <div className="mb-0 pb-0">
            <img
              className="img-fluid cursor-pointer recipe-featured-image rounded"
              src={isNotEmpty(recipe.photo) ? assetUrl(recipe.photo) : `${process.env.PUBLIC_URL}/images/recipe-placeholder-picture.png`}
              alt={`whole-food plant-based ${recipe.name}`}
              onClick={(e) => {
                e.stopPropagation();
                setLightBoxOpened(true);
              }}
            />
          </div>
          <div className="mb-0 pb-0">
            <h1 className="mb-0">{recipe.name}</h1>
            <p className="lead mb-0 p-0">{recipe.description}</p>
            <img
              src={`https://assets.cleanfooddirtygirl.com/static/clean-food-dirty-girl-print-logo.jpeg`}
              width="170"
              className="recipe-watermark"
              alt="watermark"
            />
          </div>
        </div>

        <div className="col-md-5 d-print-none">
          <img
            className="img-fluid cursor-pointer recipe-featured-image rounded"
            src={isNotEmpty(recipe.photo) ? assetUrl(recipe.photo) : `${process.env.PUBLIC_URL}/images/recipe-placeholder-picture.png`}
            alt={`whole-food plant-based ${recipe.name}`}
            onClick={(e) => {
              e.stopPropagation();
              setLightBoxOpened(true);
            }}
          />
        </div>

        <div className="col-sm-12 col-md-7 p-3 p-md-4 my-auto col-print recipe-info d-print-none">
          <div className="ratings recipe-ratings">
            <div className="float-start text-muted pe-1">Rate it: </div>
            <ReviewStars
              stars={recipe.stars}
              cta={() =>
                props.modals.showReviews({
                  documentId: props.recipeId,
                  documentType: documentTypes.RECIPE,
                  reviewsFor: `${recipe.name}`,
                })
              }
            />
            <h1 className="mb-1">{recipe.name}</h1>
            <Allergens allergens={recipe.allergens} />
            <p className="lead my-2">{recipe.description}</p>
            <RecipeTiming prepTime={recipe.prepTime} readyIn={recipe.readyIn} context={contexts.RECIPE} />

            {props.member.hasMP && fromMealPlannerSearch ? (
              <div className="d-flex justify-content-start">
                <div className="me-2">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    <i className="fa-regular fa-circle-chevron-left" /> Back to {props.mmpStore.state.plan.name}
                  </button>
                </div>
                <div className="#">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      const data = props.mmpStore.state.plan.mealSlot;
                      data["recipeId"] = recipe.id;
                      data["portions"] = recipe.portions;
                      props.mmpStore.addMeal(data, () => {
                        props.mmpStore.setMealSlot(null);
                        history.push(`/meal-plans/meal-planner/${makeUrlLookGood(props.mmpStore.state.plan.id, props.mmpStore.state.plan.name)}`);
                      });
                    }}
                  >
                    <i className="fas fa-plus" /> Add recipe to {props.mmpStore.state.plan.name}
                  </button>
                </div>
              </div>
            ) : null}

            <div className="d-print-none mt-2">
              {props.member.hasMP ? (
                fromMealPlannerSearch ? null : (
                  <button
                    className="btn btn-light me-1"
                    type="button"
                    title="Add recipe to Meal Planner"
                    onClick={() =>
                      props.modals.showAddToMealPlan({
                        documentId: recipe.id,
                        documentType: documentTypes.RECIPE,
                        name: recipe.name,
                      })
                    }
                  >
                    <span className="fa fa-plus" />
                  </button>
                )
              ) : null}
              <StashButton
                documentId={props.recipeId}
                documentType={documentTypes.RECIPE}
                margin=" me-1"
                title="Save"
                active={stashItem === undefined ? false : stashItem.saved}
                activeClassName="fa fa-folder-bookmark"
                inactiveClassName="far fa-folder-bookmark"
                toggle={props.stashStore.toggleSaved}
              />
              <StashButton
                documentId={props.recipeId}
                documentType={documentTypes.RECIPE}
                margin=" me-1"
                title="Love"
                active={stashItem === undefined ? false : stashItem.loved}
                activeClassName="fa fa-heart"
                inactiveClassName="far fa-heart"
                toggle={props.stashStore.toggleLove}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row bg-white mt-4 py-3 mb-2 p-md-2 px-md-2 px-lg-4 gx-0 align-items-center text-center d-print-none">
        {isNotEmpty(props.changePortions) ? (
          <div className="col-auto ps-md-2 p-md-1 align-items-center mx-auto me-md-2 text-md-start servings-wrap order-md-first">
            <div className="input-group">
              <PortionSelector
                allowZero={false}
                specialScaling={props.specialScalingIds.indexOf(recipe.id) !== -1}
                defaultPortions={recipe.defaultPortions}
                portions={portions()}
                change={changePortions}
              />
            </div>
          </div>
        ) : null}

        {/* Recipe nutrition, notes, print   */}
        <div className="col-12 col-md-auto py-2 py-md-0">
          {props.canShowNutritionFacts ? (
            <button
              className="btn btn-light me-1"
              type="button"
              title="Nutrition Info"
              onClick={() => props.modals.showNutritionFacts(props.prepareDataForNutritionFacts(props.recipeId))}
            >
              <i className="fa-solid fa-chart-simple-horizontal" />
            </button>
          ) : null}

          <button className="btn btn-light me-1 position-relative" type="button" title="Notes" onClick={() => setShowNotes(!showNotes)}>
            <i className={showNotes ? "notes-icon fa-solid fa-note" : "notes-icon far fa-sticky-note"} />
            {noteBadge()}
          </button>

          {downloading ? (
            <button className="btn btn-light me-1" type="button" title="Downloading..." disabled={true}>
              <i className="fa-solid fas fa-download fa-fade" />
            </button>
          ) : (
            <button className="btn btn-light me-1" type="button" title="Download" onClick={download}>
              <i className="fa-solid fas fa-download" />
            </button>
          )}
          {props.context === contexts.STANDALONE_RECIPE ? (
            <button className="btn btn-light" type="button" title="Reset" onClick={showResetRecipeModal}>
              <i className="fa-solid fas fa-arrows-rotate" />
            </button>
          ) : null}
        </div>

        <div className="col text-center text-md-end">
          {props.recipes.length > 1 ? (
            <>
              <button className="btn btn-light me-1" type="button" title="Collapse all" onClick={() => setCollapseAll(true)}>
                <i className="fa-solid fa-chevrons-up" />
              </button>
              <button className="btn btn-light" type="button" title="Expand all" onClick={() => setExpandAll(true)}>
                <i className="fa-solid fa-chevrons-down" />
              </button>
            </>
          ) : null}
        </div>

        {/* Uncheck box
          <div className="col-md-auto ps-md-2 p-md-2 mb-2 mb-md-0 text-center text-md-start portions-recipe order-md-first">
              <div className="btn btn-light btn-mid">Uncheck all boxes</div>
          </div>
          */}
        {restoreDefaultRecipeButton()}
        {showNotes ? (
          <div className="row my-2 my-md-1 pb-md-1 mx-auto">
            <div className="col"></div>
            <Notes documentId={recipe.id} documentType={documentTypes.RECIPE} store={props.noteStore} documentPublicNotes={recipe.notes} />
          </div>
        ) : null}
      </div>
      {props.member && (props.member.hasRV || props.member.hasTrial) && (
        <div className="d-none d-print-block display-2">
           <p>
            A printable recipe is just a click away! Simply tap the download icon <img src={`${process.env.PUBLIC_URL}/images/download-icon.png`} width="15" className="Download icon" alt="Download recipe" />, and you'll be ready to print and cook these delicious recipes in no time. <br /><br />
            If you're on the free trial, downloads are disabled. Upgrade to Recipe Club or Meal Plan Club to unlock downloads and get this plant-powered party started!
        </p>
        </div>
      )}
      <div className={`col-12 ${props.member && (props.member.hasRV || props.member.hasTrial) ? "d-print-none" : ""}`}>
        <br />
        {recipes()}
        {props.showRelatedContent ? (
          <FeaturedIn
            authenticated={props.authenticated}
            member={props.member}
            stashStore={props.stashStore}
            modals={props.modals}
            token={props.token}
            recipeId={recipe.id}
          />
        ) : null}
      </div>

      <div id="reset-modal" className="modal" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title h-alt">Reset recipe</h1>
              <button type="button" className="btn-close btn-close-black" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <p>Resetting this recipe will remove all checkmarks and restore the recipe to default.</p>
              <p>Would you like to proceed?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">
                No, cancel
              </button>
              <button type="button" className="btn btn-secondary me-auto" onClick={confirmDeleteCustomizations}>
                Yes, reset
              </button>
            </div>
          </div>
        </div>
      </div>
      <MemberOnlyFeatureModal member={props.member} />
    </div>
  ) : null;
}

Recipe.propTypes = {
  token: PropTypes.string,
  authenticated: PropTypes.bool,
  member: PropTypes.object,
  context: PropTypes.string,
  recipeId: PropTypes.number,
  recipes: PropTypes.array,
  rawRecipes: PropTypes.array,
  childRecipeIds: PropTypes.array,
  deletedChildRecipes: PropTypes.array,
  stashStore: PropTypes.object,
  reviewStore: PropTypes.object,
  noteStore: PropTypes.object,
  mmpStore: PropTypes.object,
  modals: PropTypes.object,
  showRelatedContent: PropTypes.bool,
  canShowNutritionFacts: PropTypes.bool,
  specialScalingIds: PropTypes.array,
  updatingCheckboxes: PropTypes.bool,
  changePortions: PropTypes.func,
  changeCookingMethod: PropTypes.func,
  updateCheckboxes: PropTypes.func,
  deleteChildRecipe: PropTypes.func,
  restoreChildRecipes: PropTypes.func,
  updateChildRecipeAdditionalPortions: PropTypes.func,
  prepareDataForNutritionFacts: PropTypes.func,
  deleteCustomizations: PropTypes.func,
  recordDownload: PropTypes.func,
};
