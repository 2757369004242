import Notification from "./Notification";
import {useState} from "react";

export default function NotificationList({ notificationStore }) {
  const notificationListCap = 4;
  const initialNotificationListLength = 3;
  const { notifications, dismissAll, dismissNotification } = notificationStore;
  const [batchEnd, setBatchEnd] = useState(initialNotificationListLength);
  const [isViewAllMode, setIsViewAllMode] = useState(false);

  if (!notifications?.length > 0) return null;

  const unseenNotifications = notifications.filter((n) => !n.seen);
  const sortedNotifications = notifications.sort((a, b) => a.seen - b.seen);

  const incrementBatchEnd = () => {
    setIsViewAllMode(true);
    if (batchEnd >= notifications.length) return;
    else if (batchEnd === initialNotificationListLength) setBatchEnd(notificationListCap);
    else setBatchEnd((size) => size + notificationListCap);
  };
  const resetBatch = () => {
    setIsViewAllMode(false);
    setBatchEnd(initialNotificationListLength);
  };

  const batchStart = batchEnd <= notificationListCap ? 0 : batchEnd - notificationListCap;

  const shownNotifications = isViewAllMode
      ? sortedNotifications.slice(batchStart, batchEnd)
      : unseenNotifications.slice(0, batchEnd);

  const isViewMoreDisabled = batchEnd >= sortedNotifications.length;
  return (
    <div className="notification-list">
      {shownNotifications.length > 0 ? (
          shownNotifications.map((n) => (
              <Notification
                  key={n.id}
                  notification={n}
                  onDismissClick={dismissNotification}
              />
          ))
      ) : (
          <div
              className="custom-link menu-text"
              onClick={incrementBatchEnd}>
            View notification history
          </div>
      )}
      <div className="d-flex justify-content-between">
        <div>
          {(shownNotifications?.length > 0 && !isViewMoreDisabled)  &&
            <button type="button"
                  className="btn btn-link text-decoration-underline"
                  style={{ paddingLeft: "0" }}
                  onClick={incrementBatchEnd}>
            View more
          </button>
          }
          {(batchEnd > initialNotificationListLength || unseenNotifications?.length < shownNotifications?.length) && (
            <button type="button"
                    title="Collapse"
                    className="collapse-btn"
                    onClick={resetBatch}>
              <i className="fas fa-solid fa-chevron-up" />
            </button>
          )}
        </div>
        {unseenNotifications?.length > 0 &&(
        <button type="button" className="btn btn-link text-decoration-underline" onClick={dismissAll}>
          Dismiss all
        </button>
        )}
      </div>
    </div>
  );
}
