import "./PasswordForm.css";
import React, {useEffect, useReducer} from "react";
import PropTypes from "prop-types";
import Helpers from "../../../services/Helpers";
import {Collapse} from "bootstrap";

const actions = {
  SHOW: "show",
  CANCEL: "cancel",
  PASSWORD: "password",
  SHOW_PASSWORD: "showPassword",
  PASSWORD_CONFIRMATION: "passwordConfirmation",
  SHOW_PASSWORD_CONFIRMATION: "showPasswordConfirmation",
  VALIDATE: "validate",
  WORKING: "working",
  HIDE: "hide",
}

const initialState = {
  show: false,
  password: "",
  showPassword: false,
  passwordInvalid: false,
  passwordConfirmation: "",
  showPasswordConfirmation: false,
  passwordConfirmationInvalid: false,
  readyToSubmit: false,
  working: false,
}

export default function PasswordForm(props) {
  const {isNotEmpty} = Helpers();
  function validatePassword(password) {
    return isNotEmpty(password) && password.length > 7;
  }
  function reducer(state, action) {
    if (action.type === actions.SHOW) {
      return {...state, show: action.data.show};
    } else if (action.type === actions.CANCEL || action.type === actions.HIDE) {
      return initialState;
    } else if (action.type === actions.WORKING) {
      return {...state, working: action.data.working};
    } else if (action.type === actions.PASSWORD) {
      return {...state, password: action.data.value, passwordInvalid: false};
    } else if (action.type === actions.SHOW_PASSWORD) {
      return {...state, showPassword: action.data.checked};
    } else if (action.type === actions.PASSWORD_CONFIRMATION) {
      return {...state, passwordConfirmation: action.data.value, passwordConfirmationInvalid: false};
    } else if (action.type === actions.SHOW_PASSWORD_CONFIRMATION) {
      return {...state, showPasswordConfirmation: action.data.checked};
    } else if (action.type === actions.VALIDATE) {
      const passwordValid = validatePassword(state.password);
      const passwordConfirmationValid = validatePassword(state.passwordConfirmation) && state.password === state.passwordConfirmation;
      const readyToSubmit = passwordValid && passwordConfirmationValid;
      return {...state, passwordInvalid: !passwordValid, passwordConfirmationInvalid: !passwordConfirmationValid, readyToSubmit: readyToSubmit}
    }
    return state;
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const element = document.getElementById("update-password");
    const collapse = new Collapse(element, { toggle: false });
    state.show ? collapse.show() : collapse.hide();
  }, [state.show]);

  useEffect(() => {
    if (state.readyToSubmit) {
      dispatch({
        type: actions.WORKING,
        data: { working: true }
      });
    }
  }, [state.readyToSubmit]);
  
  useEffect(() => {
    if (state.readyToSubmit && state.working) {
      props.changePassword({ password: state.password }, () => {
        dispatch({type: actions.HIDE})
      });
    }
  }, [state.working]);

  function handleSubmit(event) {
    event.preventDefault();
    dispatch({ 
      type: actions.VALIDATE,
    });
  }

  const button = state.working ? (
    <button className="btn btn-primary ms-2" disabled={true}>
      <span className="spinner-border spinner-border-sm" />
      <span className="ms-1">Saving...</span>
    </button>
  ) : (
    <button className="btn btn-primary ms-2" type="submit">
      Save
    </button>
  );

  return (
    <div className="accordion-item">
      <div className="accordion-header">
        <button
          className={state.show ? "accordion-button" : "accordion-button collapsed"}
          type="button"
          onClick={() => dispatch({ type: actions.SHOW, data: { show: !state.show } })}
        >
          <span>Update password</span>
        </button>
      </div>
      <div id="update-password" className="accordion-collapse collapse">
        <div className="accordion-body">
          <form onSubmit={handleSubmit} noValidate={true}>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                New password
              </label>
              <div className="input-group">
                <input
                  type={state.showPassword ? "text" : "password"}
                  autoComplete="off"
                  className={state.passwordInvalid ? "form-control is-invalid" : "form-control"}
                  id="password"
                  placeholder="Type your password here..."
                  value={state.password}
                  pattern=".{8,}"
                  required={true}
                  onChange={e => dispatch({ type: actions.PASSWORD, data: { value: e.target.value }, })}
                />
                <button
                  className="btn btn-outline-light"
                  id="btn-show-password"
                  type="button"
                  onClick={() => dispatch({ type: actions.SHOW_PASSWORD, data: { checked: !state.showPassword } })}
                  title={state.showPassword ? "Hide password" : "Show password"}
                >
                  <i className={`far ${state.showPassword ? "fa-eye" : "fa-eye-slash"}`}></i>
                </button>
                <div className="invalid-feedback">
                  Your password must be at least 8 characters long. You can do it!
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="passwordConfirmation" className="form-label">
                Confirm new password
              </label>
              <div className="input-group">
                <input
                  type={state.showPasswordConfirmation ? "text" : "password"}
                  autoComplete="off"
                  className={state.passwordConfirmationInvalid ? "form-control is-invalid" : "form-control"}
                  id="passwordConfirmation"
                  placeholder="Retype your new password"
                  value={state.passwordConfirmation}
                  pattern=".{8,}"
                  required={true}
                  onChange={e => dispatch({ type: actions.PASSWORD_CONFIRMATION, data: { value: e.target.value } })}
                />
                <button
                  className="btn btn-outline-light"
                  id="btn-show-password-confirmation"
                  type="button"
                  onClick={() => dispatch({ type: actions.SHOW_PASSWORD_CONFIRMATION, data: { checked: !state.showPasswordConfirmation } })}
                  title={state.showPasswordConfirmation ? "Hide password" : "Show password"}
                >
                  <i className={`far ${state.showPasswordConfirmation ? "fa-eye" : "fa-eye-slash"}`}></i>
                </button>
                <div className="invalid-feedback">
                  {state.passwordConfirmation.length < 8 ? "Your password must be at least 8 characters long. You can do it!" : "Both passwords must match. Make sure you type them in correctly."}
                </div>
              </div>
            </div>
            <div>
              {state.working ? (
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  disabled={true}
                >
                  Cancel
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => dispatch({type: actions.CANCEL})}
                >
                  Cancel
                </button>
              )}
              {button}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

PasswordForm.propTypes = {
  member: PropTypes.object,
  changePassword: PropTypes.func,
};
