import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Collapse } from "bootstrap";
import BillingInformation from "../../Subscription/BillingInformation";

export default function BillingInformationAccordion(props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const element = document.getElementById("billing-information");
    const collapse = new Collapse(element, { toggle: false });
    show ? collapse.show() : collapse.hide();
  }, [show]);

  return (
    <div className="accordion-item">
      <div className="accordion-header">
        <button className={show ? "accordion-button" : "accordion-button collapsed"} type="button" onClick={() => setShow(!show)}>
          <span>Billing information</span>
        </button>
      </div>
      <div id="billing-information" className="accordion-collapse collapse">
        <div className="accordion-body">
          <BillingInformation member={props.memberStore.member} subStore={props.subStore} signUpContext={false} useLargeButtons={true} />
        </div>
      </div>
    </div>
  );
}

BillingInformationAccordion.propTypes = {
  memberStore: PropTypes.object,
  subStore: PropTypes.object,
};
