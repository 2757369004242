import React, { useRef } from "react";
import { Link } from "react-router-dom";

import Socials from "../Socials";
import QuickLinksButton from "./QuickLinksButton";
import NotificationList from "./NotificationList";

export default function OffCanvasMenu({ quickLinks, toggleQuickLink, notificationStore, menuStore, signOut }) {
  const canvasRef = useRef();

  function renderMenu() {
    const menuItems = menuStore.items;
    let result = [];
    let previousMenuItem = {
      id: 0,
      sectionTitle: "",
      sectionIcon: "",
      sectionColor: "",
      title: "",
      link: "",
      opensInNewTab: false,
    };
    for (const key in menuItems) {
      if (Object.hasOwnProperty.call(menuItems, key)) {
        const menuItem = menuItems[key];
        // if previous menu item is not in the same section
        if (menuItem.sectionTitle !== previousMenuItem.sectionTitle) {
          // create the section header
          result.push(
            <div key={`${key}-section`} className={`menu-section text-${menuItem.sectionColor} ${previousMenuItem.id !== 0 ? "mt-3" : ""}`}>
              <i className={`menu-section-icon fa-fw ${menuItem.sectionIcon}`} />
              <span className="ms-2">{menuItem.sectionTitle}</span>
            </div>
          );
        }
        if (menuItem.opensInNewTab) {
          // create menu item if link opens in a new tab
          result.push(
            <div key={key} className={`menu-${menuItem.sectionColor} me-2`}>
              <a className="nav-link menu-text rounded ms-3" href={menuItem.link} target="_blank" rel="noreferrer">
                {menuItem.title}
              </a>
            </div>
          );
        } else {
          // create menu item if link is internal
          result.push(
            <div key={key} className={`menu-${menuItem.sectionColor} me-2`}>
              <Link className="nav-link menu-text rounded ms-3" to={menuItem.link}>
                {menuItem.title}
              </Link>
            </div>
          );
        }

        // update previous menu item with current menu item before reading next one
        previousMenuItem = menuItem;
      }
    }
    return result.length > 0 ? result : null;
  }

  return (
    <div ref={canvasRef} className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasMenu" aria-labelledby="offcanvasMenuLabel">
      <div className="offcanvas-header">
        <h5 className="mb-0 d-none" id="offcanvasMenuLabel">
          Menu
        </h5>
        <QuickLinksButton quickLinks={quickLinks} toggleQuickLink={toggleQuickLink} />
        <button type="button" className="btn-close text-reset me-sm-1" data-bs-dismiss="offcanvas" aria-label="Close" />
      </div>
      <div className="offcanvas-body pt-0">
        <NotificationList
          notificationStore={notificationStore}
        />
        {renderMenu()}

        {/* **** sign out **** */}
        <div className="menu-red mt-2 me-2">
          <a
            className="nav-link menu-text rounded text-muted ms-3"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              signOut();
            }}
          >
            Sign Out
          </a>
        </div>
        <Socials />
      </div>
    </div>
  );
}
