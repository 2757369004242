import React, {useEffect, useState} from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { Offcanvas } from "bootstrap";
import { Userpilot } from "userpilot";

import OffCanvasMenu from "./OffCanvasMenu/OffCanvasMenu";
import SignInButton from "./SignInButton";

import "./Header.css";

export default function Header({ authenticated, menuStore, quickLinks, toggleQuickLink, signOut, notificationStore }) {
  let history = useHistory();
  let location = useLocation();
  const [notificationEvent, setNotificationEvent] = useState(false);
  const { notifications } = notificationStore;
  useEffect(()=> {
    setNotificationEvent((prev) => !prev);
  },[notifications.data])

  useEffect(() => {
    if (authenticated) {
      Userpilot.initialize("NX-44ef2fc1");
      Userpilot.reload();
    }
    // Deal with menu
    const element = document.getElementById("offcanvasMenu");
    if (element !== null && element.classList.contains("show")) {
      const menu = Offcanvas.getOrCreateInstance(element);
      menu.hide();
    }
  }, [location, authenticated]);

  function anonymousButtons() {
    let result = null;
    if (location.pathname.indexOf("/forgot-password") === -1 && location.pathname.indexOf("/gift") === -1) {
      let buttons;
      buttons = (
        <>
          <Link to="/trial?utm_campaign=trial&utm_source=portal&utm_medium=button&utm_content=home+header&utm_term=try+now" className="btn btn-primary me-2">
            Start trial
          </Link>
          <SignInButton />
        </>
      );
      result = <nav className="d-inline-flex ms-md-auto header-icons header navbar navbar-light bg-white p-0">{buttons}</nav>;
    }
    return result;
  }

  function main() {
    return authenticated ? (
      <header className="main-menu fixed-top border-0 shadow-sm ps-0">
        <nav className="navbar navbar-expand navbar-light bg-white px-0 py-1 align-middle" id="main-menu">
          <div className="container-fluid px-1 align-middle">
            <Link className="me-0" to="/">
              <img src={`${process.env.PUBLIC_URL}/images/Clean-Food-Dirty-Girl-Main-Logo.png`}
                className="header-logo"
                alt="Clean Food Dirty Girl"
              />
            </Link>
            <div id="header-icons-group" className="justify-content-end">
              <div className="row align-items-center">
                <div className="col-auto p-0">
                  <button id="header-search-btn" className="btn nav-link" type="button" onClick={() => history.push("/search")}>
                    <span className="fs-4">
                      <i className="fas fa-magnifying-glass" />
                    </span>
                  </button>
                </div>
                <div className="col-auto p-0">
                  <ul className="navbar-nav my-0 my-md-2 my-lg-0 ms-auto">
                    {/* cooking journal icon */}
                    <li className="header-link-btn">
                      <Link to="/cooking-journal?d=now" className="nav-link rounded">
                        <span className="fs-4">
                          <i className="fa-solid fa-book-sparkles" />
                        </span>
                      </Link>
                    </li>
                    {/* saved icon */}
                    <li className="header-link-btn">
                      <Link to="/search?tab=loved" className="nav-link rounded">
                        <span className="fs-4">
                          <i className="fa-solid fa-folder-heart"></i>
                        </span>
                      </Link>
                    </li>
                    {/* menu icon */}
                    <li className="header-link-btn">
                      <button
                        className="btn nav-link rounded"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasMenu"
                        aria-controls="offcanvasMenu"
                        aria-label="Menu"
                      >
                        <span className="fs-4 position-relative">
                          <i className="fa-solid fa-bars pe-1"></i>
                          <Badge notifications={notifications} />
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <OffCanvasMenu
          quickLinks={quickLinks}
          toggleQuickLink={toggleQuickLink}
          notificationStore={notificationStore}
          menuStore={menuStore}
          signOut={signOut}
        />
      </header>
    ) : (
      <header className="main-menu fixed-top border-0 shadow-sm">
        <nav className="navbar navbar-expand navbar-light bg-white p-1 pe-2" id="main-menu">
          <div className="container-fluid p-0 px-md-2">
            <Link className="navbar-brand" to="/">
              <img
                src={`${process.env.PUBLIC_URL}/images/Clean-Food-Dirty-Girl-Main-Logo.png`}
                className="header-logo"
                alt="Clean Food Dirty Girl"
              />
            </Link>
            <div className="justify-content-end" id="navbarScroll">
              <ul className="navbar-nav me-auto my-0 my-md-2 my-lg-0 navbar-nav-scroll ms-auto">
                <li>{anonymousButtons()}</li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }

  return ["/sign-up", "/sign-in", "/trial", "/thank-you"].indexOf(location.pathname) !== -1 ? null : main();
}

function Badge({ notifications }) {
  if (!notifications?.length > 0) return null;
  const notSeenCount = notifications.filter((n) => !n.seen).length;
  if (!notSeenCount) return null;
  const isOver9 = notSeenCount > 9;
  return (
    <span
      className="position-absolute start-50 translate-middle-x badge rounded-pill"
      style={{ fontSize: "0.7rem", backgroundColor: "rgb(255, 92, 38)", padding: `${isOver9 ? "4px 2px" : "3px 5px"}` }}
    >
      {isOver9 ? "9+" : notSeenCount}
    </span>
  );
}
